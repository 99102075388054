<template>
    <ul class="collapse ps-3" :id="`collapse-${parent_id}`">
        <li class="nav-item" v-for="detail in datatree" :key="detail.id">
            <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 1">{{ detail.title }}</router-link>
            <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 2">{{ detail.title }}</router-link>
            <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 3" target="_blank">{{ detail.title }}</router-link>
            <router-link class="nav-link" :to="`${detail.link}`" v-if="detail.link_type == 4">{{ detail.title }}</router-link>
            <router-link class="nav-link" :to="`${detail.link}`" v-if="detail.link_type == 5" target="_blank">{{ detail.title }}</router-link>
            <treemenu :datatree="detail.submenus" :parent_id="detail.id"></treemenu>
        </li>
    </ul>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "treemenu",
  props: ["datatree", "parent_id"],
  components: {},
  data() {
    return {
      lang: i18n.global.locale,
    };
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>