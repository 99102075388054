<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xxl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xxl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{
                  first_object ? list[0].category_title : "Title"
                }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <div class="row">
              <template v-for="detail in list" :key="detail.id">
                <div class="col-xl-4 col-md-6" v-if="detail.list_title !== null">
                  <div class="news">
                    <div class="news-img">
                      <a :href="`/${lang}/detail-page/${detail.slug}`">
                        <img :src="api_url + detail.anons_image" alt="alter" />
                      </a>
                    </div>
                    <div class="news-in">
                      <div class="news-title">
                        <a :href="`/${lang}/detail-page/${detail.slug}`">{{
                          detail.list_title
                        }}</a>
                      </div>
                      <div class="news-list-date">
                        <span>{{ detail.date }}</span>
                        <a href="#">{{ detail.category_title }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="my-pagination">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <button class="page-link" @click="get_list(1)">
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="pagination.current_page == 1"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 3">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 3)"
                    >
                      {{ pagination.current_page - 3 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 2">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 2)"
                    >
                      {{ pagination.current_page - 2 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 1">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      {{ pagination.current_page - 1 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link active"
                      @click="get_list(pagination.current_page)"
                    >
                      {{ pagination.current_page }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 1)"
                    >
                      {{ pagination.current_page + 1 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 1 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 2)"
                    >
                      {{ pagination.current_page + 2 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 2 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 3)"
                    >
                      {{ pagination.current_page + 3 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="
                        pagination.current_page == pagination.last_page
                      "
                      @click="get_list(pagination.current_page + 1)"
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      @click="get_list(pagination.last_page)"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <myfooter></myfooter>
          </div>
        </div>
        <div class="col-xxl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import myfooter from "@/components/myfooter.vue";
import right from "@/components/right.vue";
import lang from '@/components/lang.vue';

export default {
  name: "list_1",
  components: {
    myheader,
    myfooter,
    right,
    lang,
  },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      slug: this.$route.params.slug,
      list: new Array(),
      pagination: new Object(),
    };
  },
  methods: {
    get_list(page = 1) {
      axios
        .get(
          `${this.api_url}/api/category/${this.slug}/?lang=${this.lang}&page=${page}`
        )
        .then((response) => {
          this.list = response.data.data;
          this.pagination = response.data.meta;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    first_object: function () {
      return this.list.length > 0 ? true : false;
    },
  },
  mounted() {
    this.get_list();
  },
};
</script>