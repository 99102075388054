<template>
  <section class="link">
    <div class="container">
      <div class="home-head">
        <div class="home-title">
          <span>{{ $t("link") }}</span>
        </div>
        <div class="home-line"></div>
      </div>
      <div class="owl-link">
        <div class="owl-carousel owl-theme">
          <template v-for="detail in list" :key="detail.id">
            <div class="item">
              <div class="link-in">
                <div class="link-icon">
                  <a :href="detail.link" target="_blank">
                    <img :src="api_url + detail.image" />
                  </a>
                </div>
                <div class="link-right">
                  <a class="left" :href="detail.link" target="_blank">{{
                    detail.list_title
                  }}</a>
                  <a class="right" :href="detail.link" target="_blank">{{
                    detail.link
                  }}</a>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
  <footer>
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-lg-6">
            <div class="f-logo">
              <router-link :to="{ name: 'home' }">
                <img src="/img/f-logo.png" />
                <span v-html="$t('logo')"></span>
              </router-link>
            </div>
            <div class="f-social">
              <div class="f-social-title">
                <span>{{ $t("social") }}:</span>
              </div>
              <div class="f-social-in">
                <template v-for="detail in social" :key="detail.id">
                  <a :href="detail.link" target="_blank">
                    <img :src="api_url + detail.image" />
                  </a>
                </template>
              </div>
            </div>
          </div>

          <div class="col-xxl-7" v-for="tag in detail" :key="tag.id">
            <div class="row">
              <div class="col-lg-5">
                <div class="f-data">
                  <div class="f-left">
                    <span>{{ $t("work") }}:</span>
                  </div>
                  <div class="f-right">
                    <a href="#"></a>
                    <span>{{ tag.working_time }}</span>
                  </div>
                </div>
                <div class="f-data">
                  <div class="f-left">
                    <span>{{ $t("lunch") }}:</span>
                  </div>
                  <div class="f-right">
                    <a href="#"></a>
                    <span>{{ tag.lunch }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="f-data">
                  <div class="f-left">
                    <span>{{ $t("phone") }}:</span>
                  </div>
                  <div class="f-right">
                    <a href="#">{{ tag.phone }}</a>
                    <a href="#">{{ tag.phone2 }}</a>
                    <span></span>
                  </div>
                </div>
                <div class="f-data">
                  <div class="f-left">
                    <span>{{ $t("address") }}:</span>
                  </div>
                  <div class="f-right">
                    <a href="#"></a>
                    <span
                      >{{ tag.address }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="f-line"></div>
    <div class="container">
      <div class="container">
        <div class="row">
          <div class="col-xxl-5 col-xl-6">
            <div class="f-description">
              <span>{{ $t("site") }}</span>
            </div>
            
          </div>
          <div class="col-xxl-4 col-xl-6">
            <div class="f-description">
              <span v-html="$t('copy')"></span>
            </div>
          </div>
          <div class="col-xxl-3 col-xl-12">
            <div class="uzinfocom">
              <a href="#">
                <img src="/img/uzinfocom.png" />
              </a>
            </div>
          </div>
          <div class="col-xxl-12 col-xl-12">
            <div class="f-description">
              <span
                >{{ $t("ctrl") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";

export default {
  name: "myfooter",
  components: {},
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      list: new Array(),
      social: new Array(),
      detail: new Object(),
    };
  },
  methods: {
    home_function() {
      $(".owl-link .owl-carousel").owlCarousel({
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
        autoplay: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 2,
          },
          1200: {
            items: 3,
          },
          1400: {
            items: 2,
          },
          1600: {
            items: 3,
          },
        },
      });
    },
    get_list() {
      axios
        .get(`${this.api_url}/api/useful?lang=${this.lang}`)
        .then((response) => {
          this.list = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.home_function();
        });
    },
    get_social() {
      axios
        .get(`${this.api_url}/api/socials?lang=${this.lang}`)
        .then((response) => {
          this.social = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    get_detail() {
      axios
        .get(this.api_url + "/api/contact/?lang=" + this.lang)
        .then((response) => {
          this.detail = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
  computed: {},
  mounted() {
    this.get_list();
    this.get_social();
    this.get_detail();
  },
  created() {},
};
</script>