export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz hello i18n !!"])},
  "andijon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андижон вилояти"])},
  "fargona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фарғона вилояти"])},
  "namangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наманган вилояти"])},
  "toshkent_sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тошкент шаҳри"])},
  "toshkent_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тошкент вилояти"])},
  "sirdaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сирдарё вилояти"])},
  "jizzax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жиззах вилояти"])},
  "samarqand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самарқанд вилояти"])},
  "qashqadaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қашқадарё вилояти"])},
  "surxondaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурхондарё вилояти"])},
  "buxoro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бухоро вилояти"])},
  "xorazm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хоразм вилояти"])},
  "navoiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навоий вилояти"])},
  "qoraqolpoq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қорақалпоғистон Республикаси"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тошкент давлат<br> стоматология институти"])},
  "logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тошкент давлат стоматология институти"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Излаш"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тозалаш"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янгиликлар"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуал қабулхона"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилоятни танланг"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туманни танланг"])},
  "republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республика бўйича"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барчаси"])},
  "illness_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касаллик турини танланг"])},
  "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касалликни танланг"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жинсини танланг"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Йилни танланг"])},
  "all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республика бўйича барча маълумотлар"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фойдали ҳаволалар"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иш тартиби"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тушлик"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манзил"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материаллардан фойдаланишда www.oralhealthmap.uz га ҳавола бўлиши шарт"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диққат! Агар матнда хато топсангиз, уни танланг ва маъмуриятни хабардор қилиш учун Ctrl + Enter  тугмаларини босинг"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бўлишиш"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelга экпорт"])},
  "export-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манба билан excelга экспорт"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ўтган йилга нисбатан"])},
  "illness_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касалликлар категорияси"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биография"])},
  "func": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функциялар"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кўриш"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИШ"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лавозими"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабул кунлари"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«Ўзбекистон Республикасида стоматологик касалликлар тарқалишининг географик харитаси» ягона ахборот тизими"])},
  "age_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ёшдан"])},
  "age_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ёшгача"])},
  "stat_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касалликлар бўйича аёллар ва эркаклар статистикаси"])},
  "stat_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регионлар бўйича аёллар ва эркаклар статистикаси"])},
  "stat_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касалликлар бўйича иккита вилоят солиштирмаси"])},
  "all_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умумий статистика"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эркаклар"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аёллар"])},
  "illness_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касаллик кўрсаткичи"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воқеалар тақвими"])},
  "diogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диаграмма кўриниши"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жадвал кўриниши"])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Февраль"])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Март"])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апрель"])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Май"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июнь"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июль"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Август"])},
  "m9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сентябрь"])},
  "m10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Октябрь"])},
  "m11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ноябрь"])},
  "m12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декабрь"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мўлжал"])},
  "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транспорт"])},
  "weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дам олиш куни"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факс"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрон манзил"])},
  "stat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилоятлар бўйича иккита ёш солиштирмаси"])},
  "stat5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Касалликлар бўйича иккита ёш солиштирмаси"])},
  "age1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биринчи ёш"])},
  "age2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иккинчи ёш"])},
  "region_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биринчи вилоят"])},
  "region_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иккинчи вилоят"])},
  "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Саҳифа топилмади"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бош саҳифага ўтиш"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сана"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барча ҳуқуқлар ҳимояланган"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ёш"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Давлат"])},
  "xususiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хусусий"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Харитада кўриш"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоматология клиникалари"])}
}