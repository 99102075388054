import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    messages: Array(),
    right_list: Array(),
  },
  getters: {},
  mutations: {
    set_message_mutation(state, message) {
      state.messages = message;
    },
    set_right_list_mutation(state, list) {
      state.right_list = list;
    },
  },
  actions: {
    async set_message_action({commit}, message) {
      commit('set_message_mutation', message);
    },
    async set_right_list_action({commit}, list) {
      commit('set_right_list_mutation', list);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
})
