<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xxl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xxl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{
                  first_object ? list[0].m_category_title : "Title"
                }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <div class="row">

              <template v-for="detail in list" :key="detail.id">
                <div class="col-lg-12">
                  <div class="leader">
                    <div class="row">
                      <div class="col-lg-4 col-md-5">
                        <div class="leader-image">
                          <img :src="api_url + detail.anons_image" alt="alter" />
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-7">
                        <div class="leader-right">
                          <div class="leader-name row">
                            <span class="left col-md-3">{{ $t("fullname") }}:</span>
                            <span class="right col-md-8">{{ detail.management_leader }}</span>
                          </div>
                          <div class="leader-position row">
                            <span class="left col-md-3">{{ $t("position") }}:</span>
                            <span class="right col-md-8">{{ detail.management_title }}</span>
                          </div>
                          <div class="leader-phone row">
                            <span class="left col-md-3">{{ $t("phone") }}:</span>
                            <a class="col-md-8" href="#">{{ detail.phone }}</a>
                          </div>
                          <div class="leader-reception row">
                            <span class="left col-md-3">{{ $t("reception") }}:</span>
                            <span class="right col-md-8">{{ detail.management_reception }}</span>
                          </div>
                          <div class="leader-reception row">
                            <span class="left col-md-3">Email:</span>
                            <span class="right col-md-8">{{ detail.email }}</span>
                          </div>
                          
                          <div class="accordion mt-3" :id="`accordionExample${detail.id}`">
                            <div class="accordion-nav">
                              <h2 :id="`headingOne${detail.id}`" v-if="detail.management_biography !== null">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne${detail.id}`" aria-expanded="false" :aria-controls="`collapseOne${detail.id}`">
                                  {{ $t("bio") }}
                                </button>
                              </h2>
                              <h2 :id="`headingTwo${detail.id}`" v-if="detail.management_description !== null">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseTwo${detail.id}`" aria-expanded="false" :aria-controls="`collapseTwo${detail.id}`">
                                  {{ $t("func") }}
                                </button>
                              </h2>
                            </div>

                            <div :id="`collapseOne${detail.id}`" class="accordion-collapse collapse" :aria-labelledby="`headingOne${detail.id}`" :data-bs-parent="`#accordionExample${detail.id}`">
                              <div class="mt-3 text-justify" v-html="detail.management_biography"></div>
                            </div>
                            <div :id="`collapseTwo${detail.id}`" class="accordion-collapse collapse" :aria-labelledby="`headingTwo${detail.id}`" :data-bs-parent="`#accordionExample${detail.id}`">
                              <div class="mt-3 text-justify" v-html="detail.management_description"></div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

            </div>
          </div>
          <myfooter></myfooter>
        </div>
        <div class="col-xxl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import lang from "@/components/lang.vue";
import myfooter from "@/components/myfooter.vue";

export default {
  name: "list_4",
  components: {
    myheader,
    right,
    lang,
    myfooter,
  },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      slug: this.$route.params.slug,
      list: new Array(),
    };
  },
  methods: {
    get_list(page = 1) {
      axios
        .get(
          `${this.api_url}/api/managements/${this.slug}/?lang=${this.lang}&page=${page}`
        )
        .then((response) => {
          this.list = response.data.managements;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    first_object: function () {
      return this.list.length > 0 ? true : false;
    },
  },
  mounted() {
    this.get_list();
  },
};
</script>