import { createRouter, createWebHistory, RouterView } from 'vue-router'
import i18n from '../i18n'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import error from '../views/error.vue'
import contact from '../views/contact.vue'
import help from '../views/help.vue'
import list_1 from '../views/list_1.vue'
import detail_1 from '../views/detail_1.vue'
import detail_2 from '../views/detail_2.vue'
import list_2 from '../views/list_2.vue'
import list_3 from '../views/list_3.vue'
import list_4 from '../views/list_4.vue'
import list_5 from '../views/list_5.vue'
import stat from '../views/stat.vue'
import stat2 from '../views/stat2.vue'
import stat3 from '../views/stat3.vue'
import stat4 from '../views/stat4.vue'
import stat5 from '../views/stat5.vue'
import list_search from '../views/list_search.vue'
import list_base from '../views/list_base.vue'
import company from "../views/company.vue";

const routes = [
  {
    path: '/',
    redirect: `${i18n.global.locale}`,
  },
  {
    path: '/:lang',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'detail/about/:slug',
        name: 'about',
        component: AboutView,
      },
      {
        path: 'error',
        name: 'error',
        component: error,
      },
      {
        path: 'detail-contact/:slug',
        name: 'contact',
        component: contact,
      },
      {
        path: 'help',
        name: 'help',
        component: help,
      },
      {
        path: 'detail-page/:slug',
        name: 'detail_1',
        component: detail_1,
      },
      {
        path: 'detail/detail_2/:slug',
        name: 'detail_2',
        component: detail_2,
      },
      {
        path: 'list/list_base/:slug',
        name: 'list_base',
        component: list_base,
      },
      {
        path: 'list-news/:slug',
        name: 'list_1',
        component: list_1,
      },
      {
        path: 'list-foto-gallery/:slug',
        name: 'list_2',
        component: list_2,
      },
      {
        path: 'list-video-gallery/:slug',
        name: 'list_3',
        component: list_3,
      },
      {
        path: 'list-managements/:slug',
        name: 'list_4',
        component: list_4,
      },
      {
        path: 'list-documents/:slug',
        name: 'list_5',
        component: list_5,
      },
      {
        path: 'gender-by-illness',
        name: 'stat',
        component: stat,
      },
      {
        path: 'gender-by-region',
        name: 'stat2',
        component: stat2,
      },
      {
        path: 'region-by-illness',
        name: 'stat3',
        component: stat3,
      },
      {
        path: 'age-by-region',
        name: 'stat4',
        component: stat4,
      },
      {
        path: 'age-by-illness',
        name: 'stat5',
        component: stat5,
      },
      {
        path: 'list/list_search',
        name: 'list_search',
        component: list_search,
      },
      {
        path: 'company',
        name: 'company',
        component: company,
      },
    ],
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  i18n.global.locale = to.params.lang;
  next();
})

export default router
