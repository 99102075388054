export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ru message"])},
  "andijon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Андижанская область"])},
  "fargona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ферганская область"])},
  "namangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наманганская область"])},
  "toshkent_sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г. Ташкент"])},
  "toshkent_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ташкентская область"])},
  "sirdaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сырдарьинская область"])},
  "jizzax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Джизакская область"])},
  "samarqand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самаркандская область"])},
  "qashqadaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кашкадарьинская область"])},
  "surxondaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сурхандарьинская область"])},
  "buxoro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бухарская область"])},
  "xorazm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хорезмская область"])},
  "navoiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Навоийская область"])},
  "qoraqolpoq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Республика Каракалпакстан"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ташкентский государственный стоматологический институт"])},
  "logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ташкентский государственный стоматологический институт"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очистить"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальная приёмная"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите регион"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите район"])},
  "republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По Республике"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "illness_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите тип заболевания"])},
  "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите заболевание"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите пол"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите год"])},
  "all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся информация о республике"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полезные ссылки"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обед"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании материалов ссылка на www.oralhealthmap.uz обязательна"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Если Вы нашли ошибку в тексте, выделите её и нажмите Ctrl+Enter для уведомления администрации"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экпорт в Excel"])},
  "export-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать в exel с источником"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по сравнению с прошлым годом"])},
  "illness_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория болезней"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биография"])},
  "func": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Должность"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни приёма"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единая информационная система «Географическая карта распространения стоматологических заболеваний в Республике Узбекистан»"])},
  "age_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От (возраст)"])},
  "age_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До (возраст)"])},
  "stat_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика мужчин и женщин по болезням"])},
  "stat_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика мужчин и женщин по регионам"])},
  "stat_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнение двух регионов по болезям"])},
  "all_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая статистика"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужчины"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Женщины"])},
  "illness_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показатель болезни"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь событий"])},
  "diogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В виде диаграммы"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В виде таблицы"])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Январь"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Февраль"])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Март"])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апрель"])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Май"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июнь"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Июль"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Август"])},
  "m9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сентябрь"])},
  "m10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Октябрь"])},
  "m11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ноябрь"])},
  "m12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декабрь"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ориентир"])},
  "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транспорт"])},
  "weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выходной"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Факс"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронный адрес"])},
  "stat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сравнение двух возрастов по регионам"])},
  "stat5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Два возрастных сравнения по заболеваниям"])},
  "age1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый возраст"])},
  "age2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй возраст"])},
  "region_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый регион"])},
  "region_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй регион"])},
  "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти на главную страницу"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все права защищены"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лет"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Государственный"])},
  "xususiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частная"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть на карте"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоматологические клиники"])}
}