export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En hello i18n !!"])},
  "andijon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andijon viloyati"])},
  "fargona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farg`ona viloyati"])},
  "namangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan viloyati"])},
  "toshkent_sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toshkent shahri"])},
  "toshkent_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toshkent viloyati"])},
  "sirdaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirdaryo viloyati"])},
  "jizzax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jizzax viloyati"])},
  "samarqand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarqand viloyati"])},
  "qashqadaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qashqadaryo viloyati"])},
  "surxondaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surxondaryo viloyati"])},
  "buxoro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buxoro viloyati"])},
  "xorazm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xorazm viloyati"])},
  "navoiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navoiy viloyati"])},
  "qoraqolpoq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qoraqalpog`iston Respublikasi"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toshkent davlat stomatologiya instituti"])},
  "logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toshkent davlat stomatologiya instituti"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izlash"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tozalash"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangiliklar"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual qabulxona"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viloyatni tanlang"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumanni tanlang"])},
  "republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respublika bo’yicha"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barchasi"])},
  "illness_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasallik turini tanlang"])},
  "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasallikni tanlang"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jinsni tanlang"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yilni tanlang"])},
  "all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respublika bo’yicha barcha ma'lumotlar"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydali havolalar"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ish tartibi"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tushlik"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiallardan foydalanishda www.oralhealthmap.uz ga havola boʻlishi shart"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diqqat! Agar matnda xato topsangiz, uni tanlang va ma'muriyatni xabardor qilish uchun Ctrl + Enter tugmalarini bosing"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bo’lishish"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excelga eksport"])},
  "export-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manba bilan excelga eksport"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o'tgan yilga nisbatan"])},
  "illness_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasalliklar kategoriyasi"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografiya"])},
  "func": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktsiyalar"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko'rish"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F.I.SH"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lavozimi"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qabul kunlari"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“O‘zbekiston Respublikasida stomatologik kasalliklar tarqalishining geografik xaritasi” yagona axborot tizimi"])},
  "age_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoshdan"])},
  "age_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yoshgacha"])},
  "stat_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassaliklar bo'yicha Ayollar va erkaklar statistikasi"])},
  "stat_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regionlar bo'yicha Ayollar va erkaklar statistikasi"])},
  "stat_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassaliklar bo'yicha ikkita viloyat solishtirmasi"])},
  "all_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umumiy statistika"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkaklar"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayollar"])},
  "illness_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasallik ko’rsatkichi"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voqealar taqvimi"])},
  "diogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diagramma ko’rinishi"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jadval ko’rinishi"])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yanvar"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fevral"])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mart"])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprel"])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyun"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iyul"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avgust"])},
  "m9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sentyabr"])},
  "m10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktyabr"])},
  "m11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noyabr"])},
  "m12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekabr"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo'ljal"])},
  "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dam olish kuni"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faks"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektron manzil"])},
  "stat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viloyatlar bo‘yicha ikkita yosh solishtirmasi"])},
  "stat5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasalliklar bo‘yicha ikkita yosh solishtirmasi"])},
  "age1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birinchi yosh"])},
  "age2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkinchi yosh"])},
  "region_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birinchi viloyat"])},
  "region_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkinchi viloyat"])},
  "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahifa topilmadi"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["osh sahifaga o'tish"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sana"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcha huquqlar himoyalangan"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yosh"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Davlat"])},
  "xususiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xususiy"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xaritada ko'rish"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stomatologiya klinikalari"])}
}