<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{ detail.category_title }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <div class="detail">
              <div class="detail-title">
                <span>{{ detail.list_title }}</span>
              </div>
              <div class="detail-date">
                <span>Sana: &nbsp;</span>
                <span>{{ detail.date }}</span>
              </div>
              <div class="detail-description">
                <span>{{ detail.list_description }}</span>
              </div>
              <div class="detail-owl">
                <div class="owl-carousel owl-theme">

                  <template v-if="detail.body_image !== undefined && detail.body_image !== null">
                    <div class="item" v-for="(tag, index) in split(detail.body_image)" :key="index">
                        <div class="detail-img">
                            <img :src="api_url + tag" alt="alter">
                        </div>
                    </div>
                  </template>
                  
                </div>
              </div>
              <div class="detail-content">
                <div v-html="detail.list_content"></div>
              </div>
            </div>
            <myfooter></myfooter>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import myfooter from "@/components/myfooter.vue";
import lang from '@/components/lang.vue';

export default {
  name: "detail_2",
  components: {
    myheader,
    right,
    myfooter,
    lang,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      slug: this.$route.params.slug,
      detail: new Object,
    };
  },
  methods: {
    get_detail() {
      axios
        .get(this.api_url + "/api/pages/" + this.slug + "/?lang=" + this.lang)
        .then((response) => {
          this.detail = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.main_function();
        });
    },
    split(text) {
      return text.split(",");
    },
    main_function() {
      $(".detail-owl .owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        autoplay: true,
        autoHeight: true,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      });
    },
  },
  computed: {},
  mounted() {
    this.get_detail();
  },
};
</script>