<template>
  <div class="calendar">
    <div class="oy">
      <div class="title_box">
        <span>{{ $t("calendar") }}</span>
      </div>

      <div class="calendarBox">
        <div class="calView">
          <h2>{{ $t("calendar") }}</h2>
          <button type="button" class="close closebtn">
            <span aria-hidden="true">x</span>
          </button>
          <ul class="calData"></ul>
        </div>
        <table id="calendar" class="cal" month="10" year="2022">
          <thead>
            <tr>
              <td class="prev">
                &lt;
              </td>
              <td colspan="5"></td>
              <td class="next">
                &gt;
              </td>
            </tr>
            <tr>
              <td>Пн</td>
              <td>Вт</td>
              <td>Ср</td>
              <td>Чт</td>
              <td>Пт</td>
              <td>Сб</td>
              <td>Вс</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>1</td>
              <td>2</td>
              <td>3</td>
              <td>4</td>
              <td>5</td>
              <td>6</td>
            </tr>
            <tr>
              <td>7</td>
              <td>8</td>
              <td>9</td>
              <td>10</td>
              <td>11</td>
              <td>12</td>
              <td>13</td>
            </tr>
            <tr>
              <td>14</td>
              <td>15</td>
              <td>16</td>
              <td>17</td>
              <td>18</td>
              <td>19</td>
              <td>20</td>
            </tr>
            <tr>
              <td>21</td>
              <td class="today">22</td>
              <td>23</td>
              <td>24</td>
              <td>25</td>
              <td>26</td>
              <td>27</td>
            </tr>
            <tr>
              <td>28</td>
              <td>29</td>
              <td>30</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="news-card">
    <div class="news-title">
      <span>{{ $t("news") }}</span>
    </div>

    <template v-for="detail in list" :key="detail.id">
      <div class="news-item" v-if="detail.list_title !== null">
        <div class="row">
          <div class="col-12">
            <div class="news-link">
              <router-link :to="`/${lang}/detail-page/${detail.slug}`">
                <span>{{ detail.list_title }}</span>
              </router-link>
            </div>
          </div>
          <div class="col-12">
            <div class="news-date-link">
              <router-link :to="`/${lang}/detail-page/${detail.slug}`">
                <div class="news-date">
                  <div class="news-m">
                    <span>{{ detail.date }}</span>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import { mapActions } from 'vuex';

export default {
  name: "right",
  components: {},
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      list: new Array(),
    };
  },
  methods: {
    ...mapActions(['set_right_list_action']),
    get_list() {
      axios
        .get(`${this.api_url}/api/news-component/?lang=${this.lang}`)
        .then((response) => {
          this.list = response.data.data;
          this.cal(response.data.data);
          // this.set_right_list_action(response.data.data);
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cal(news, lng = this.lang) {
      calendar("calendar", new Date().getFullYear(), new Date().getMonth());

      $('#calendar .prev').click(function() {
        calendar("calendar", $('#calendar').attr('year'), parseFloat($('#calendar').attr('month'))-1);
      });

      $('#calendar .next').click(function() {
        calendar("calendar", $('#calendar').attr('year'), parseFloat($('#calendar').attr('month'))+1);
      });

      $('.calendarBox .calView .closebtn').click(function(e){
        e.preventDefault();
        $('.calendarBox .cal').fadeTo("fast", 1);
        $('.calendarBox .calView').fadeOut();
        $('#calendar').removeClass('blur');
      });

      
      
      function calendar(id, year, month, list = news, lang = lng) {
        
          let eventsList = Array();
          
          for (const key in list) {
            
            let object = new Object({
              id: String(),
              title: String(),
              date: String(),
            });

            object.id = list[key].slug;
            object.title = list[key].list_title;
            object.date = list[key].date;
            eventsList[key] = object;

          }

          let monthsList = Array();

          switch(lang) {
            case 'uz':
              monthsList = ["Январь" , "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
              break;
            case 'oz':
              monthsList = ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"];
              break;
            case 'ru':
              monthsList = ["Январь" ,"Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];
              break;
            case 'en':
              monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
              break;
            default:
              console.log(5);
          }

          var Dlast = new Date(year,month+1,0).getDate(),
            D = new Date(year,month,Dlast),
            DNlast = new Date(D.getFullYear(),D.getMonth(),Dlast).getDay(),
            DNfirst = new Date(D.getFullYear(),D.getMonth(),1).getDay(),
            calendar = '<tr>',
            month = monthsList;

          if (DNfirst != 0) {
              for(var  i = 1; i < DNfirst; i++) calendar += '<td>';
          }else{
              for(var  i = 0; i < 6; i++) calendar += '<td>';
          }

          for(var  i = 1; i <= Dlast; i++) {
            var clalEvent = [];
            for (var j=0;j<eventsList.length;j++){
              if (typeof(eventsList[j]) != 'undefined') {
                var dat = new Date(eventsList[j].date);
                if (D.getFullYear() == dat.getFullYear() && D.getMonth() == dat.getMonth() && i == dat.getDate()) {
                  clalEvent.push(eventsList[j]);
                }
              }
            }

            if (clalEvent.length > 0){
              calendar += '<td class="active" day="'+i+'">' + i;
            } else {
              //console.log('0:'+i+' 1:'+new Date().getDate()+ ' 2:'+ D.getFullYear()+' 3:'+ new Date().getFullYear()+' 4:'+ D.getMonth()+' 5:'+ new Date().getMonth());
              if (i == new Date().getDate() && D.getFullYear() == new Date().getFullYear() && D.getMonth() == new Date().getMonth()) {
                calendar += '<td class="today">' + i;
              }else{
                calendar += '<td>' + i;
              }
            }
            if (new Date(D.getFullYear(),D.getMonth(),i).getDay() == 0) {
              calendar += '<tr>';
            }
          }
          for(var  i = DNlast; i < 7; i++) calendar += '<td>&nbsp;</td>';
          $('#'+id+' tbody').html(calendar);
          $('#'+id+' thead td').eq(1).html(month[D.getMonth()] +' '+ D.getFullYear());
          $('#'+id+'').attr('month', D.getMonth());
          $('#'+id+'').attr('year', D.getFullYear());

          if ($('#'+id+' tbody tr').length < 6) {
              $('#'+id+' tbody').append('<tr><td>&nbsp</td><td>&nbsp</td><td>&nbsp</td><td>&nbsp</td><td>&nbsp</td><td>&nbsp</td><td>&nbsp</td></tr>');
          }

          $('#calendar td').click(function(){
            if ($(this).hasClass('active')){
              var day = $(this).attr('day');
              var month = $('#calendar').attr('month');
              var year = $('#calendar').attr('year');
              var lng = $('#calendar').attr('lng');
              var calDetalls = '';
              for (var g=0;g<eventsList.length;g++){
                  var dat = new Date(eventsList[g].date);
                  if (year == dat.getFullYear() && month == dat.getMonth() && day == dat.getDate())
                  {
                      calDetalls += '<li>'+eventsList[g].date+'<a href="/'+ lang +'/detail-page/'+eventsList[g].id+'">'+eventsList[g].title+'</a></li>';
                  }
              }
              $('.calendarBox .calView .calData').html(calDetalls);
              $('.calendarBox .calView').fadeIn();
              //$('.calendarBox .cal').fadeTo("fast", 0.33);
              $('#calendar').addClass('blur');
            }
          });
      }
      
    },
    local_check() {
      if (this.$store.state.right_list == 0) {
        this.get_list();
      } else {
        this.list = this.$store.state.right_list;
      }
    },
  },
  computed: {},
  mounted() {
    this.get_list();
  },
  created() {},
};
</script>