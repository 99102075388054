<template>
  <div class="marquue">
    <marquee behavior="alternate">
      <span>&nbsp;&nbsp;Sayt test rejimida ishlamoqda / Сайт тест режмида ишламоқда / The site is running in test mode / Сайт работает в тестовом режиме&nbsp;&nbsp;</span>
    </marquee>
  </div>
  <div class="my-header">
    <div class="header-left">
      <router-link :to="{ name: 'home' }">
        <div class="header-name">
          <img src="/img/logo1.png" alt="alter">
          <span>{{ $t("name") }}</span>
        </div>
      </router-link>
    </div>
    <div class="header-right">
      <div class="home-search">
        <form :action="`/${lang}/list/list_search`">
          <input type="search" name="search" :placeholder="$t('search')" />
          <button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.236"
              height="17.211"
              viewBox="0 0 17.236 17.211"
            >
              <path
                d="M21.921,22.942l-5.867-5.867a5.365,5.365,0,0,1-1.7.986,6.025,6.025,0,0,1-2.069.353,6.061,6.061,0,0,1-4.455-1.826A6,6,0,0,1,6,12.182,6,6,0,0,1,7.826,7.776,6.033,6.033,0,0,1,12.256,5.95a5.964,5.964,0,0,1,4.394,1.826A6.231,6.231,0,0,1,18.123,14.2,6.179,6.179,0,0,1,17.1,16.028L23.017,21.9a.638.638,0,0,1,.219.5.757.757,0,0,1-.243.548.764.764,0,0,1-1.071,0Zm-9.665-5.989a4.556,4.556,0,0,0,3.359-1.4A4.616,4.616,0,0,0,17,12.182,4.616,4.616,0,0,0,15.616,8.81a4.556,4.556,0,0,0-3.359-1.4,4.779,4.779,0,0,0-4.8,4.771,4.779,4.779,0,0,0,4.8,4.771Z"
                transform="translate(-6 -5.95)"
              />
            </svg>
          </button>
        </form>
      </div>
      <div class="eye special_box dropdown">
          <button class="eye-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.771"
            height="17.855"
            viewBox="0 0 25.771 17.855"
          >
            <path
              d="M15.235,21.986a5.03,5.03,0,0,0,5.059-5.059,5.03,5.03,0,0,0-5.059-5.059,5.03,5.03,0,0,0-5.059,5.059,5.03,5.03,0,0,0,5.059,5.059Zm0-1.726A3.3,3.3,0,0,1,11.9,16.927a3.3,3.3,0,0,1,3.333-3.333,3.3,3.3,0,0,1,3.333,3.333,3.3,3.3,0,0,1-3.333,3.333Zm0,5.595a13.287,13.287,0,0,1-7.484-2.232,15.062,15.062,0,0,1-5.223-5.892,1.165,1.165,0,0,1-.134-.357,2.254,2.254,0,0,1,0-.893,1.165,1.165,0,0,1,.134-.357,15.062,15.062,0,0,1,5.223-5.892A13.287,13.287,0,0,1,15.235,8a13.287,13.287,0,0,1,7.484,2.232,15.062,15.062,0,0,1,5.223,5.892,1.165,1.165,0,0,1,.134.357,2.254,2.254,0,0,1,0,.893,1.165,1.165,0,0,1-.134.357,15.062,15.062,0,0,1-5.223,5.892A13.287,13.287,0,0,1,15.235,25.855ZM15.235,16.927Zm0,7.142a11.967,11.967,0,0,0,6.621-1.949,12.648,12.648,0,0,0,4.6-5.193,12.649,12.649,0,0,0-4.6-5.193,12.221,12.221,0,0,0-13.242,0,12.861,12.861,0,0,0-4.627,5.193A12.861,12.861,0,0,0,8.614,22.12,11.967,11.967,0,0,0,15.235,24.069Z"
              transform="translate(-2.35 -8)"
            />
          </svg>
          </button>
          <div class="dropdown-menu specialViewArea no-propagation" aria-labelledby="dropdownMenuButton1">
              <div class="triangle2"></div>
              <div class="appearance">
                  <p class="specialTitle">Кўриниш</p>
                  <div class="squareAppearances">
                      <div class="squareBox spcNormal" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Оддий кўриниш">A</div>
                  </div>
                  <div class="squareAppearances">
                      <div class="squareBox spcWhiteAndBlack" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Оқ-қора кўриниш">A</div>
                  </div>
                  <div class="squareAppearances">
                      <div class="squareBox spcDark" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Қоронғилашган кўриниш">A</div>
                  </div>
                  <div class="squareAppearances">
                      <div class="squareBox spcNoImage" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Расмли"></div>
                  </div>
              </div>
              <div class="appearance">
                  <p class="specialTitle">Шрифт ўлчами</p>
                  <div class="block blocked">
                      <div class="sliderText"><span class="range">0</span>% га катталаштириш</div>
                      <div id="fontSizer" class="defaultSlider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"><div class="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style="width: 0%;"></div><span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style="left: 0%;"></span></div>
                  </div>
                  <div class="block">
                      <div class="sliderZoom"><span class="range">0</span> % баробарга катталаштириш</div>
                      <div id="zoomSizer" class="defaultSlider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"><div class="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style="width: 0%;"></div><span class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0" style="left: 0%;"></span></div>
                  </div>
              </div>
          </div>
      </div>
      <div class="lang">
        <div class="dropdown">
          <a
            class="lang-link"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span v-if="lang == 'uz'">Ўз</span>
            <span v-else-if="lang == 'oz'">O'z</span>
            <span v-else-if="lang == 'ru'">Ру</span>
            <span v-else-if="lang == 'en'">En</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.711"
              height="7.239"
              viewBox="0 0 12.711 7.239"
            >
              <path
                d="M19.013,24.454A.822.822,0,0,1,18.7,24.4a.853.853,0,0,1-.28-.2l-5.541-5.541a.77.77,0,0,1-.21-.6.861.861,0,0,1,.238-.6.7.7,0,0,1,.6-.238,1.052,1.052,0,0,1,.574.266l4.925,4.925,4.925-4.925a.914.914,0,0,1,.6-.252.662.662,0,0,1,.6.252.617.617,0,0,1,.238.588,1.09,1.09,0,0,1-.266.616L19.6,24.2a.853.853,0,0,1-.28.2.822.822,0,0,1-.308.056Z"
                transform="translate(-12.674 -17.215)"
              />
            </svg>
          </a>
          <ul class="dropdown-menu">
            <li v-if="lang !== 'uz'">
              <button class="dropdown-item" @click="locale('uz')">Ўз</button>
            </li>
            <li v-if="lang !== 'oz'">
              <button class="dropdown-item" @click="locale('oz')">O'z</button>
            </li>
            <li v-if="lang !== 'en'">
              <button class="dropdown-item" @click="locale('en')">En</button>
            </li>
            <li v-if="lang !== 'ru'">
              <button class="dropdown-item" @click="locale('ru')">Ру</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapActions } from 'vuex';
import axios from 'axios';


export default {
  name: "lang",
  components: {},
  data() {
    return {
      protocol: location.protocol,
      host: location.host,
      path: location.pathname.substring(3),
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      messages: [],
      // mes: [
      //   en = [
      //     address = String("Addess en"),
      //     age1 = "First age en"
      //   ],
      //   uz = [
      //     address = "Addess uz",
      //     age1 = "First age uz"
      //   ],
      //   oz = [
      //     address = "Addess oz",
      //     age1 = "First age oz"
      //   ],
      //   ru = [
      //     address = "Addess ru",
      //     age1 = "First age ru"
      //   ],
      // ],
    };
  },
  methods: {
    ...mapActions(["set_message_action"]),
    locale(lang) {
      window.location.href = `${this.protocol}//${this.host}/${lang}${this.path}`;
    },
    get_messages() {
      axios
        .get(`${this.api_url}/api/messages`)
        .then((response) => {
          this.messages = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_messages();
    this.set_message_action([1,2,3,4,5,6]);
    // console.log(this.$store.state.messages);
  },
};
</script>