<template>
    <p>Help</p>
</template>

<script>
export default {
    name: 'help',
    components: {},
    data() {
        return {}
    },
    methods: {},
    computed: {},
    mounted() {},
}
</script>