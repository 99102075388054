<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{ search }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <div class="search-content">
              <div class="card mt-3" v-for="detail in list" :key="detail.id">
                <div class="card-header">
                  {{ detail.category_title }}
                </div>
                <div class="card-body">
                  <h5 class="card-title">{{ detail.list_title }}</h5>
                  <p class="card-text">{{ detail.list_description }}</p>
                  <a href="#" class="btn btn-primary">Batafsil</a>
                </div>
              </div>
            </div>
            <div class="my-pagination">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <button class="page-link" @click="get_list(1)">
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="pagination.current_page == 1"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 3">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 3)"
                    >
                      {{ pagination.current_page - 3 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 2">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 2)"
                    >
                      {{ pagination.current_page - 2 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 1">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      {{ pagination.current_page - 1 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link active"
                      @click="get_list(pagination.current_page)"
                    >
                      {{ pagination.current_page }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 1)"
                    >
                      {{ pagination.current_page + 1 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 1 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 2)"
                    >
                      {{ pagination.current_page + 2 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 2 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 3)"
                    >
                      {{ pagination.current_page + 3 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="
                        pagination.current_page == pagination.last_page
                      "
                      @click="get_list(pagination.current_page + 1)"
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      @click="get_list(pagination.last_page)"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <myfooter></myfooter>
        </div>
        <div class="col-xl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import lang from "@/components/lang.vue";
import myfooter from "@/components/myfooter.vue";

export default {
  name: "list_search",
  components: {
    myheader,
    right,
    lang,
    myfooter,
  },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      list: new Array(),
      pagination: new Object(),
      search: this.$route.query.search,
    };
  },
  methods: {
    get_list(page = 1) {
      axios
        .get(
          `${this.api_url}/api?lang=${this.lang}&search=${this.search}&page=${page}`
        )
        .then((response) => {
          this.list = response.data.data;
          this.pagination = response.data.meta;
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.get_list();
  },
};
</script>