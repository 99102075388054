<template>
  <header class="home">
    <div class="container">
      <div class="row align-items-xxl-center">
        <div class="col-xxl-12">
          <div class="logo">
            <router-link :to="{ name: 'home' }">
              <div class="logo-in">
                <div class="logo-icon">
                  <img src="/img/logo.svg" />
                </div>
                <div class="logo-namee">
                  <span v-html="$t('logo')"></span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
  
  <section class="menu-home">
    <div class="container">
      <div class="menu">
        
        <nav class="navbar">
          <div class="container-fluid">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div
              class="collapse navbar-collapse"
              id="navbar"
            >
              <ul class="navbar-nav">
          
                <template v-for="detail in menu" :key="detail.id">
                  
                  <li class="nav-item" v-if="detail.submenus.length == 0">
                    <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 1">{{ detail.title }}</router-link>
                    <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 2">{{ detail.title }}</router-link>
                    <router-link class="nav-link" :to="`/${lang}${detail.link}`" v-if="detail.link_type == 3" target="_blank">{{ detail.title }}</router-link>
                    <router-link class="nav-link" :to="`${detail.link}`" v-if="detail.link_type == 4">{{ detail.title }}</router-link>
                    <router-link class="nav-link" :to="`${detail.link}`" v-if="detail.link_type == 5" target="_blank">{{ detail.title }}</router-link>
                  </li>

                  <li class="nav-item" v-else>
                    <a
                      class="nav-link"
                      data-bs-toggle="collapse"
                      :href="`#collapse-${detail.id}`"
                      role="button"
                      aria-expanded="false"
                      :aria-controls="`#collapse-${detail.id}`"
                    >
                      <span>{{ detail.title }}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.711"
                        height="7.239"
                        viewBox="0 0 12.711 7.239"
                      >
                        <path
                          d="M19.013,24.454A.822.822,0,0,1,18.7,24.4a.853.853,0,0,1-.28-.2l-5.541-5.541a.77.77,0,0,1-.21-.6.861.861,0,0,1,.238-.6.7.7,0,0,1,.6-.238,1.052,1.052,0,0,1,.574.266l4.925,4.925,4.925-4.925a.914.914,0,0,1,.6-.252.662.662,0,0,1,.6.252.617.617,0,0,1,.238.588,1.09,1.09,0,0,1-.266.616L19.6,24.2a.853.853,0,0,1-.28.2.822.822,0,0,1-.308.056Z"
                          transform="translate(-12.674 -17.215)"
                          
                        />
                      </svg>
                    </a>
                    <treemenu :datatree="detail.submenus" :parent_id="detail.id"></treemenu>
                  </li>
                </template>

              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import i18n from "../i18n";
import treemenu from "./treemenu.vue";

export default {
  name: "myheader",
  components: {
    treemenu,
  },
  data() {
    return {
      protocol: location.protocol,
      host: location.host,
      path: location.pathname.substring(3),
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      menu: new Array(),
    };
  },
  methods: {
    locale(lang) {
      window.location.href = `${this.protocol}//${this.host}/${lang}${this.path}`;
    },
    get_menu() {
      axios
        .get(this.api_url + "/api/menus/?lang=" + this.lang)
        .then((response) => {
          this.menu = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
  computed: {},
  mounted() {
    this.get_menu();
    document.title = this.$t("logo_title");
  },
};
</script>