<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{ $t("title") }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <div class="row">
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="select-control">
                  <div class="form-group">
                    <label for="sel-1">{{ $t("region") }}</label>
                    <select
                      class="form-control"
                      id="sel-1"
                      @change="select_region($event)"
                    >
                      <option value="0">{{ $t("republic") }}</option>
                      <option
                        v-for="item in region_list"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="select-control">
                  <div class="form-group">
                    <label for="sel-1">{{ $t("district") }}</label>
                    <select
                      class="form-control"
                      @change="select_district($event)"
                      :disabled="district_disabled"
                    >
                      <option value="0">{{ $t("all") }}</option>
                      <option
                        v-for="item in district_list"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="company-search">
                  <button class="select-submit" @click="get_list()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <rect id="BG" width="24" height="24" fill="none" />
                      <path
                        id="color"
                        d="M16,17a1,1,0,0,1-.613-.21l-.094-.084-3.245-3.243A7.432,7.432,0,0,1,7.5,15,7.5,7.5,0,1,1,15,7.5a7.431,7.431,0,0,1-1.536,4.549l3.243,3.245A1,1,0,0,1,16,17ZM7.5,2a5.5,5.5,0,1,0,3.546,9.7,1,1,0,0,1,.659-.66A5.5,5.5,0,0,0,7.5,2Z"
                        transform="translate(3 3)"
                        fill="#fff"
                      />
                    </svg>
                    <span>{{ $t('search') }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <template v-for="(detail, index) in list" :key="index">
                <div class="col-xxl-4 col-md-6">
                 <div class="company-in">
                  <div class="company-img">
                    <img :src="`${api_url}${detail.image}`" alt="rasm">
                  </div>
                  <div class="company-name">
                    <a href="#">{{ detail.title }}</a>
                  </div>
                  <div class="company-region">
                    <span>{{ detail.region_id }}, {{ detail.district_id }}, {{ detail.address }}</span>
                  </div>
                  <div class="company-phone">
                    <span>{{ $t('phone') }}</span>:&nbsp; <a style="font-weight: 500" :href="`tel:${detail.phone}`">{{ detail.phone }}</a>
                  </div>
                  <div class="company-district">
                    <span>{{ $t('work') }}</span>:&nbsp; <span style="font-weight: 500">{{ detail.working_time }}</span>
                  </div>
                  <div class="company-national">
                    <span v-if="detail.type == 1">{{ $t("country") }}</span>
                    <span v-if="detail.type == 2">{{ $t("xususiy") }}</span>
                  </div>
                  <div class="company-location">
                    <a :href="`https://maps.google.com?q=${detail.latitude},${detail.longitude}`" target="_blank">
                      <img src="/css/images/marker-icon.png" alt="location">
                      <span>{{ $t("map") }}</span>
                    </a>
                  </div>
                 </div>
                </div>
              </template>
            </div>
            <div class="my-pagination">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <button class="page-link" @click="get_list(1)">
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="pagination.current_page == 1"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      <span aria-hidden="true">&lt;</span>
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 3">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 3)"
                    >
                      {{ pagination.current_page - 3 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 2">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 2)"
                    >
                      {{ pagination.current_page - 2 }}
                    </button>
                  </li>

                  <li class="page-item" v-if="pagination.current_page > 1">
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page - 1)"
                    >
                      {{ pagination.current_page - 1 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link active"
                      @click="get_list(pagination.current_page)"
                    >
                      {{ pagination.current_page }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 1)"
                    >
                      {{ pagination.current_page + 1 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 1 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 2)"
                    >
                      {{ pagination.current_page + 2 }}
                    </button>
                  </li>

                  <li
                    class="page-item"
                    v-if="pagination.current_page + 2 < pagination.last_page"
                  >
                    <button
                      class="page-link"
                      @click="get_list(pagination.current_page + 3)"
                    >
                      {{ pagination.current_page + 3 }}
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      :disabled="
                        pagination.current_page == pagination.last_page
                      "
                      @click="get_list(pagination.current_page + 1)"
                    >
                      <span aria-hidden="true">&gt;</span>
                    </button>
                  </li>

                  <li class="page-item">
                    <button
                      class="page-link"
                      @click="get_list(pagination.last_page)"
                    >
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <myfooter></myfooter>
        </div>
        <div class="col-xl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import lang from "@/components/lang.vue";
import myfooter from "@/components/myfooter.vue";

export default {
  name: "list_base",
  components: {
    myheader,
    right,
    lang,
    myfooter,
  },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      slug: this.$route.params.slug,
      list: new Array(),
      pagination: new Object(),
      region_id: Number(),
      district_id: Number(),
      district_list: Array(),
      region_list: Array(),
      district_disabled: Boolean(true),
    };
  },
  methods: {
    get_list(page = 1) {
      axios
        .get(
          `${this.api_url}/api/establishments?${this.api_c}&lang=${this.lang}&page=${page}`
        )
        .then((response) => {
          this.list = response.data.data;
          this.pagination = response.data.meta;
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_region(event) {
      let id = event.target.value;
      this.district_disabled = false;
      this.region_id = id;
      this.district_id = 0;
      this.get_district(id);
    },
    get_district(id = 1703) {
      axios
        .get(
          `${this.api_url}/api/get-districts?parent_id=${id}&lang=${this.lang}`
        )
        .then((response) => {
          this.district_list = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_district(event) {
      let id = event.target.value;
      this.district_id = id;
    },
    get_region() {
      axios
        .get(`${this.api_url}/api/get-regions/?lang=${this.lang}`)
        .then((response) => {
          this.region_list = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    region_id_c: function () {
      return this.region_id == 0
        ? ""
        : "&region_id=" + this.region_id;
    },
    district_id_c: function () {
      return this.district_id == 0
        ? ""
        : "&district_id=" + this.district_id;
    },
    api_c: function () {
      return this.region_id_c + this.district_id_c;
    }
  },
  mounted() {
    this.get_list();
    this.get_region();
  },
};
</script>