<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xxl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xxl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{ detail.list_title }}</span>
              </div>
              <div class="home-line"></div>
            </div>

            <div class="detail">
              <div class="detail-description">
                <span>{{ detail.list_description }}</span>
              </div>
              <div class="row justify-content-center">
                <div class="col-xl-6 col-md-8">
                  <div class="detail-content">
                    <table class="table table-striped" v-for="tag in detail" :key="tag.id">
                        <tbody>
                            <tr v-if="tag.address !== null">
                                <th scope="row">{{ $t("address") }}: </th>
                                <td>{{ tag.address }}</td>
                            </tr>
                            <tr v-if="tag.reception !== null">
                                <th scope="row">{{ $t("reception") }}: </th>
                                <td>{{ tag.reception }}</td>
                            </tr>
                            <tr v-if="tag.working_time !== null">
                                <th scope="row">{{ $t("work") }}: </th>
                                <td>{{ tag.working_time }}</td>
                            </tr>
                            <tr v-if="tag.lunch !== null">
                                <th scope="row">{{ $t("lunch") }}: </th>
                                <td>{{ tag.lunch }}</td>
                            </tr>
                            <tr v-if="tag.landmark !== null">
                                <th scope="row">{{ $t('location') }}: </th>
                                <td>{{ tag.landmark }}</td>
                            </tr>
                            <tr v-if="tag.transport !== null">
                                <th scope="row">{{ $t('trans') }}: </th>
                                <td>{{ tag.transport }}</td>
                            </tr>
                            <tr v-if="tag.weekend !== null">
                                <th scope="row">{{ $t('weekend') }}: </th>
                                <td>{{ tag.weekend }}</td>
                            </tr>
                            <tr v-if="tag.phone !== null">
                                <th scope="row">{{ $t("phone") }}: </th>
                                <td>{{ tag.phone }}</td>
                            </tr>
                            <tr v-if="tag.phone2 !== null">
                                <th scope="row">{{ $t("phone") }}: </th>
                                <td>{{ tag.phone2 }}</td>
                            </tr>
                            <tr v-if="tag.fax !== null">
                                <th scope="row">{{ $t('fax') }}: </th>
                                <td>{{ tag.fax }}</td>
                            </tr>
                            <tr v-if="tag.email !== null">
                                <th scope="row">{{ $t('email') }}: </th>
                                <td>{{ tag.email }}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-xl-6 col-md-8">
                  <div class="map">
                    <div id="map"></div>
                  </div>
                </div>
              </div>
            </div>
            <myfooter></myfooter>
          </div>
        </div>
        <div class="col-xxl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import myfooter from "@/components/myfooter.vue";
import lang from "@/components/lang.vue";

export default {
  name: "contact",
  components: {
    myheader,
    right,
    myfooter,
    lang,
  },
  data() {
    return {
      api_url: process.env.VUE_APP_API_URL,
      lang: i18n.global.locale,
      slug: this.$route.params.slug,
      detail: new Object(),
    };
  },
  methods: {
    get_detail() {
      axios
        .get(this.api_url + "/api/" + this.slug + "/?lang=" + this.lang)
        .then((response) => {
          this.detail = response.data.data;
          this.location(
            (response.data.data[0].latitude),
            (response.data.data[0].longitude)
          );
        //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    split(text) {
      return text.split(",");
    },
    location(a = 41, b = 69) {
      var map = L.map("map", {
        center: [a, b],
        zoom: 12,
      });
      let open_street = L.tileLayer(
        "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      );
      let google_street = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        { subdomains: ["mt0", "mt1", "mt2", "mt3"] }
      );
      let google_satellite = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        { subdomains: ["mt0", "mt1", "mt2", "mt3"] }
      );
      let google_hybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        { subdomains: ["mt0", "mt1", "mt2", "mt3"] }
      );
      L.marker([a, b]).addTo(map);
      let baseMaps = {
        "Open Street Map": open_street,
        "Google Streets": google_street,
        "Google Satellite": google_satellite,
        "Google Hybrid": google_hybrid,
      };
      google_street.addTo(map);
      L.control.layers(baseMaps, null, {}).addTo(map);
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.get_detail();
  },
};
</script>