export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En hello i18n !!"])},
  "andijon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andijan region"])},
  "fargona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fergana region"])},
  "namangan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namangan region"])},
  "toshkent_sh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tashkent city"])},
  "toshkent_v": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tashkent region"])},
  "sirdaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirdarya region"])},
  "jizzax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jizzakh region"])},
  "samarqand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samarkand region"])},
  "qashqadaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kashkadarya region"])},
  "surxondaryo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surkhandarya region"])},
  "buxoro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bukhara region"])},
  "xorazm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khorazm region"])},
  "navoiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navoi region"])},
  "qoraqolpoq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karakalpakstan republic"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tashkent State Dental Institute"])},
  "logo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tashkent State Dental Institute"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtual Reception"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a region"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a district"])},
  "republic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Republic"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "illness_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the type of disease"])},
  "illness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a disease"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose gender"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select year"])},
  "all_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All information about the republic"])},
  "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working mode"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using materials, a link to www.oralhealthmap.uz is required"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! If you find an error in the text, select it and press Ctrl + Enter to notify the administration"])},
  "social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to excel with source"])},
  "export-source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel"])},
  "stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compared to last year"])},
  "illness_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illnesses category"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biography"])},
  "func": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Functions"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "fullname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullname"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception days"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unified information system 'Geographical map of the spread of dental diseases in the Republic of Uzbekistan'"])},
  "age_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from the age"])},
  "age_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the age"])},
  "stat_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disease statistics for men and women"])},
  "stat_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics of men and women by region"])},
  "stat_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison of two regions by disease"])},
  "all_stat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total statistics"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Women"])},
  "illness_index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disease index"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar of events"])},
  "diogram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a diagram"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a table"])},
  "m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
  "m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
  "m3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
  "m4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
  "m5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
  "m6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
  "m7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
  "m8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
  "m9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
  "m10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
  "m11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
  "m12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["landmark"])},
  "trans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
  "weekend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekend"])},
  "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "stat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison of two ages by region"])},
  "stat5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two age comparisons of diseases"])},
  "age1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First age"])},
  "age2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second age"])},
  "region_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First region"])},
  "region_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second region"])},
  "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to home page"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сountry"])},
  "xususiy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View on the map"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dental clinics"])}
}