<template>
  <main class="page">
    <myheader></myheader>
    <section class="inner">
      <div class="container">
        <span>{{ slug }}</span>
      </div>
    </section>
    <myfooter></myfooter>
  </main>
</template>

<script>
import i18n from '@/i18n'
import axios from 'axios'
import myheader from '@/components/myheader.vue'
import myfooter from '@/components/myfooter.vue'

export default {
  name: 'AboutView',
  components: {
    myheader,
    myfooter,
  },
  data() {
    return {
      lang: i18n.global.locale,
      slug: this.$route.params,
    }
  },
  methods: {},
  computed: {},
  mounted() {},
}
</script>
