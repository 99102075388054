<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-xxl-2">
          <div class="home-left">
            <myheader></myheader>
          </div>
        </div>
        <div class="col-xxl-8">
          <div class="home pt-4">
            <lang></lang>
            <div class="home-head">
              <div class="home-title">
                <span>{{ $t("all_stat") }}</span>
              </div>
              <div class="home-line"></div>
            </div>
            <!-- 2 -->
            <div class="table-inner mt-4">

              <div class="table-select">
                <div class="text-center pt-2">
                  <h3>{{ $t("stat_2") }}</h3>
                </div>
                <div class="row">

                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="sel-10">{{ $t("illness_category") }}</label>
                        <select
                          class="form-control"
                          @change="select_illnesses_category($event)"
                          id="sel-10"
                        >
                          <option
                            v-for="item in illnesses_category"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="illnesses">{{ $t("illness_type") }}</label>
                        <select
                          class="form-control"
                          @change="select_illnesses($event)"
                          id="illnesses"
                        >
                          <option value="0">{{ $t("all") }}</option>
                          <option
                            v-for="item in illnesses"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-3 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="sel-1">{{ $t("illness") }}</label>
                        <select
                          class="form-control"
                          @change="select_illness_children($event)"
                          :disabled="illnesses_children_disabled"
                        >
                          <option value="0">{{ $t("all") }}</option>
                          <option
                            v-for="item in illnesses_children"
                            :key="item.id"
                            :value="item.id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="year">{{ $t("year") }}</label>
                        <select class="form-control" @change="select_year($event)">
                          <option selected value="0">{{ $t('all') }}</option>
                          <option value="2030">2030</option>
                          <option value="2029">2029</option>
                          <option value="2028">2028</option>
                          <option value="2027">2027</option>
                          <option value="2026">2026</option>
                          <option value="2025">2025</option>
                          <option value="2024">2024</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="age-1">{{ $t("age_from") }}</label>
                        <input type="number" v-model="age_from" class="form-control" id="age-1">
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-2 col-lg-4 col-md-6">
                    <div class="select-control">
                      <div class="form-group">
                        <label for="age-2">{{ $t("age_to") }}</label>
                        <input type="number" v-model="age_to" class="form-control" id="age-2">
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-xl-6">
                    <div class="inner-select">
                      <button class="btn btn-primary" @click="get_gender_region()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <rect id="BG" width="24" height="24" fill="none" />
                          <path
                            id="color"
                            d="M16,17a1,1,0,0,1-.613-.21l-.094-.084-3.245-3.243A7.432,7.432,0,0,1,7.5,15,7.5,7.5,0,1,1,15,7.5a7.431,7.431,0,0,1-1.536,4.549l3.243,3.245A1,1,0,0,1,16,17ZM7.5,2a5.5,5.5,0,1,0,3.546,9.7,1,1,0,0,1,.659-.66A5.5,5.5,0,0,0,7.5,2Z"
                            transform="translate(3 3)"
                            fill="#fff"
                          />
                        </svg>
                        <span class="ms-1">{{ $t('search') }}</span>
                      </button>
                      <a :href="`${api_url}/api/export/compare/gender/by-illness?lang=${this.lang}${stat_c}`" class="btn btn-success ms-3">{{ $t("export") }}</a>
                    </div>
                  </div>
                  
                  <div class="col-xl-12">
                    <!-- <span>{{ stat_c }}</span> -->
                  </div>

                </div>
              </div>

              <ul class="nav nav-tabs mt-3" id="myTab2" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab2" data-bs-toggle="tab" data-bs-target="#home-tab-pane2" type="button" role="tab" aria-controls="home-tab-pane2" aria-selected="true">
                    {{ $t("diogram") }}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab2" data-bs-toggle="tab" data-bs-target="#profile-tab-pane2" type="button" role="tab" aria-controls="profile-tab-pane2" aria-selected="false">
                    {{ $t("table") }}
                  </button>
                </li>
              </ul>

              <div class="tab-content" id="myTabContent">
                
                <div class="tab-pane fade show active" id="home-tab-pane2" role="tabpanel" aria-labelledby="home-tab2" tabindex="0">
                  <div class="stat-chart">
                    <figure class="highcharts-figure">
                      <div id="gender_region"></div>
                    </figure>
                  </div>
                </div>
                
                <div class="tab-pane fade" id="profile-tab-pane2" role="tabpanel" aria-labelledby="profile-tab2" tabindex="0">
                  <div class="table-responsive">
                    <table class="table">
                      <tbody>
                        <template v-for="(detail, index) in gender_region" :key="index">
                          <tr>
                            <template v-for="(tag, index) in detail" :key="index">
                              <td>{{ tag.key }}</td>
                            </template>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
          <myfooter></myfooter>
        </div>
        <div class="col-xxl-2">
          <div class="home-right">
            <right></right>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import i18n from "@/i18n";
import axios from "axios";
import myheader from "@/components/myheader.vue";
import right from "@/components/right.vue";
import lang from "@/components/lang.vue";
import myfooter from "@/components/myfooter.vue";

export default {
  name: "stat2",
  components: {
    myheader,
    right,
    lang,
    myfooter,
  },
  data() {
    return {
      lang: i18n.global.locale,
      api_url: process.env.VUE_APP_API_URL,
      gender_illness: new Array(),
      gender_region: new Array(),
      region_illness: new Array(),
      region_list: new Array(),
      region_id: Number(),
      region_1: new Number(),
      region_2: new Number(),
      district_list: new Array(),
      district_disabled: Boolean(true),
      district_id: Number(),
      illnesses_category: new Array(),
      illness_category_id: Number(),
      illnesses: new Array(),
      illnesses_id: Number(0),
      illnesses_children: new Array(),
      illnesses_children_id: Number(0),
      illnesses_children_disabled: true,
      age_from: Number(),
      age_to: Number(),
      year_id: Number(),
    };
  },
  methods: {
    get_gender_region() {
      axios
        .get(
          `${this.api_url}/api/compare/gender/by-region?lang=${this.lang}${this.stat_c}`
        )
        .then((response) => {
          this.gender_region = response.data;
          this.high_gender_region(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    get_region() {
      axios
        .get(`${this.api_url}/api/get-regions/?lang=${this.lang}`)
        .then((response) => {
          this.region_list = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_region_1(event) {
      let id = event.target.value;
      this.region_1 = id;
    },
    select_region_2(event) {
      let id = event.target.value;
      this.region_2 = id;
    },
    high_gender_region(list) {
      let region = new Array();
      let male = new Array();
      let female = new Array();
      for (const key in list) {
        
        if (key > 0) {
          region.push(list[key][0].key);
          male.push(parseFloat(list[key][1].key));
          female.push(parseFloat(list[key][2].key));
        } else {
          list[key];
        }
      }
      
      Highcharts.chart('gender_region', {
        chart: {
            type: 'column',
            scrollablePlotArea: {
              minWidth: 2000,
              scrollPositionX: 1
            }
        },
        title: {
            text: '',
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            categories: region,
            crosshair: true
        },
        yAxis: {
            title: {
                useHTML: true,
                text: this.$t("illness_index"),
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.2f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: this.$t("male"),
            data: male,
            color: 'rgb(50, 100, 200)',
        }, {
            name: this.$t("female"),
            data: female,
            color: 'rgb(200, 200, 50)',
        }]
      });
    },
    get_illnesses_category() {
      axios
        .get(`${this.api_url}/api/get-illness-categories/?lang=${this.lang}`)
        .then((response) => {
          this.illnesses_category = response.data.data;
          // console.log(response.data.data);
          let id = response.data.data[0].id;
          this.illness_category_id = id;
          this.get_illnesses(id);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_illnesses_category(event) {
      let id = event.target.value;
      this.get_illnesses(id);
      this.illness_category_id = id;
      this.illnesses_id = 0;
    },
    get_illnesses(category_id = 2) {
      axios
        .get(`${this.api_url}/api/get-illnesses/?lang=${this.lang}&illness_category_id=${category_id}`)
        .then((response) => {
          this.illnesses = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_illnesses(event) {
      let id = event.target.value;
      this.illnesses_id = id;
      this.get_illness_children(id);
      this.illnesses_children_disabled = false;
    },
    get_illness_children(parent_id = 0) {
      axios
        .get(
          `${this.api_url}/api/get-illness-children/?parent_id=${parent_id}&lang=${this.lang}`
        )
        .then((response) => {
          this.illnesses_children = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_illness_children(event) {
        let id = event.target.value;
        this.illnesses_children_id = id;
    },
    select_region(event) {
      let id = event.target.value;
      this.district_disabled = false;
      this.region_id = id;
      this.district_id = 0;
      this.get_district(id);
    },
    get_district(id = 1703) {
      axios
        .get(
          `${this.api_url}/api/get-districts?parent_id=${id}&lang=${this.lang}`
        )
        .then((response) => {
          this.district_list = response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    select_district(event) {
      let id = event.target.value;
      this.district_id = id;
    },
    select_year(event) {
      let id = event.target.value;
      this.year_id = id;
    },
  },
  computed: {
    region_1_c: function () {
      return this.region_1 == 0 ? "&region1=1703" : `&region1=${this.region_1}`;
    },
    region_2_c: function () {
      return this.region_2 == 0 ? "&region2=1706" : `&region2=${this.region_2}`;
    },
    region_c: function () {
      return this.region_1_c + this.region_2_c;
    },
    illnesses_category_id_c: function () {
      return `&illness_category_id=${this.illness_category_id}`;
    },
    illnesses_id_c: function () {
      return this.illnesses_id == 0 ? '' : `&illness_parent_id=${this.illnesses_id}`;
    },
    illnesses_children_id_c: function () {
      return this.illnesses_children_id == 0 ? '' : `&illness_id=${this.illnesses_children_id}`;
    },
    region_id_c: function () {
      return this.region_id == 0 ? '' : `&region_id=${this.region_id}`;
    },
    district_id_c: function () {
      return this.district_id == 0 ? '' : `&district_id=${this.district_id}`;
    },
    year_id_c: function () {
      return this.year_id == 0 ? '' : `&year=${this.year_id}`;
    },
    age_from_c: function () {
      return this.age_from == 0 ? '' : `&age_from=${this.age_from}`;
    },
    age_to_c: function () {
      return this.age_to == 0 ? '' : `&age_to=${this.age_to}`;
    },
    stat_c: function () {
      return `${this.illnesses_category_id_c}${this.illnesses_id_c}${this.illnesses_children_id_c}${this.year_id_c}${this.age_from_c}${this.age_to_c}`;
    },
  },
  mounted() {
    this.get_gender_region();
    this.get_region();
    this.get_illnesses_category();
  },
};
</script>